/**
 * Bootstrapping.
 * Makes bluebird promises the global promise
 * Makes fetch always return a promise
 * Extends moment with moment-range
 * @module boostrap
 */
/* global process */
// global.Promise = require('bluebird');

if (process.env.NODE_ENV !== 'production') {
  global.reduxform = require('redux-form');
}

// Promise.config({
//   longStackTraces: process.env.NODE_ENV === 'development',
//   warnings: process.env.NODE_ENV === 'development',
// });

// const fetch = global.fetch;
// if (fetch) {
//   global.fetch = (...args) => Promise.resolve(fetch(...args));
// }

require('moment-range').extendMoment(require('moment'));
